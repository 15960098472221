import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-gap-16"},[(_vm.canRead)?[(_vm.searchKey)?_c(VTextField,{attrs:{"outlined":"","label":_vm.$t(("Search by " + _vm.searchKey)),"append-icon":"search","append-outer-icon":"filter_alt","hide-details":""},on:{"click:append-outer":function($event){_vm.isFilterOpen = true}},model:{value:(_vm.searchVal),callback:function ($$v) {_vm.searchVal=$$v},expression:"searchVal"}}):_vm._e(),_vm._t("before-chips"),_c('filters-chips',{attrs:{"value":_vm.currentParams,"definitions":_vm.filters},on:{"input":_vm.reloadCollection}}),_c('filters-grid-drawer',{attrs:{"open":_vm.isFilterOpen,"value":_vm.currentParams,"definitions":_vm.filters},on:{"update:open":function($event){_vm.isFilterOpen=$event},"input":_vm.reloadCollection}}),_c(VDivider)]:_vm._e(),(_vm.currentItems.length && _vm.showChips)?_c('div',{staticClass:"d-flex flex-gap-16 align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('Selected taxonomy'))+":")]),_c('deletable-chips',{attrs:{"errors":_vm.errors},on:{"onDelete":function($event){_vm.currentItems = $event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._t("deletable-chip-name",function(){return [_c('span',[_vm._v(_vm._s(item.name))])]},{"item":item})]}}],null,true),model:{value:(_vm.currentItems),callback:function ($$v) {_vm.currentItems=$$v},expression:"currentItems"}})],1):_vm._e(),(_vm.canRead)?[(_vm.currentItems.length)?_c(VDivider):_vm._e(),_c('div',[_c('resource-grid',{attrs:{"options":{ headers: _vm.gridOptions && _vm.gridOptions.headers, loading: _vm.grid.loading, meta: _vm.grid.meta },"items":_vm.grid.data,"show-select":"","single-select":!_vm.multiple,"selected-items":_vm.currentItems},on:{"update:selectedItems":_vm.onItemSelect,"onParamsChange":_vm.reloadCollection},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'taxonomy-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData || {})]}}})],null,true),model:{value:(_vm.currentParams),callback:function ($$v) {_vm.currentParams=$$v},expression:"currentParams"}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }